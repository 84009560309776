.cardMenu__item {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: rgb(241, 241, 241);
    border-radius: 10px;
    justify-content: space-between;
    height: 350px; 
    width: 350px;
    margin: 1rem;
    text-align: center;
  }
  
  .item_unavailable {
    opacity: 0.5;
  }
  
  .cardMenu__item__heading {
    text-align: center;
    color: rgb(100, 100, 100);
    font-size: 2rem;
  }
  
  .cardMenu__item__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .cardMenu__item__image-container img {
    /* taille image */
    max-width: 200px;
    max-height: 150px;
    object-fit: contain;
  }
  
  .cardMenu__item__details {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid black;
    margin: auto;
    cursor: pointer;
    
  }
  
  .cardMenu__item__details p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
   
  }
  
  
  .cardMenu__item__shopping-btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .cardMenu__item__commander-btn {
    text-align: center !important;
    /* margin: auto !important; */
    display: flex !important;
  }
  
  .myModal__header h1 {
    color: black !important;
    
  }
  
  .myModal__footer {
    justify-content: space-between !important;
  }

  .myModal__footer button{
    margin: auto;
  }

  .myModal__footer div{
    margin: auto !important;
  }
  
  .myModal__footer p {
   
  }
  
  .myModal__header p {
 
    
  }
  
  .myModal__footer div:first-child {
    justify-self: center;
    align-self: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    -moz-box-shadow-bottom: 2px 2PX 2px grey;
    -webkit-box-shadow-bottom: 2px 2px 2px grey;
    box-shadow: 2px 2px 2px grey;
    border-radius: 18px;
    padding: 0.4rem;
    margin: 0 10px;
    
  } 
  
  .MuiButtonBase-root {
    padding: 0px !important;
  }
  
  .MuiButton-label {
    background: #000000 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    padding: 1rem !important;
    color: white !important;
    font-size: 1.3rem;
    font-weight: 900;
  }
  
  @media (max-width: 1100px) {
    .cardMenu__item__heading {
      font-size: 1.7rem;
    }
  }
  
  @media (max-width: 545px) {
    .cardMenu__item__image-container img {
      /* taille image */
      max-width: 175px;
      max-height: 125px;
      object-fit: contain;
    }
    .cardMenu__item__heading {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 400px) {
    .cardMenu__item {
      width: 300px;
      margin: 1rem 0;
    }
  }

  @media (max-width: 400px) {
    .cardMenu__item {
      width: 300px;
      margin: 1rem 0;
    }
  }

  @media (max-width: 330px) {
    .cardMenu__item {
      width: 250px;
    }
  }
  