.dirigeant-header h1 {
    text-align: center;
    font-size: 3.5rem;
    padding-bottom: 1em;
    border-bottom: 1px solid gray;
    font-weight: bold;
    color: #04295D;
}

.dirigeant_container {

    display: grid;
    justify-content: center;
    flex-direction: column;

}

.dirigeant_label {
    padding: 1em;
}

.dirigeant_input {
    box-shadow: 5px 4px 11px rgb(0 0 255 / 20%);
}


.edit_button_dirigeant{
    text-align: center;
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #3EBDE6;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 11em;
    height: 3.2em;
    padding: 0; 
    margin: 1em auto;


}

.dirigeant_label {
    font-weight: bold;
}





.dirigeant_adresse {
    display: flex;
}


.dirigeant_button {
    display: flex;
    flex-wrap: wrap;
}


 .errors {
     color: black;

 }

@media screen and (max-width: 450px){
    .dirigeant_adresse {

    display: flex;
    flex-direction: column;
    }


}

@media screen and (max-width: 450px){
    .dirigeant_content{

    display: flex;
    flex-direction: column;
    }

}